import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import Event from '../utils/event';
import { getUrlParam } from '../utils/url';
import Constants from '../Constants';

export default new class StatModel {
  constructor() {
    this._betInfoCached = [];
    this._betsInfo = [];
    this._myBetsInfo = [];
    this._coefsHistory = [];
    this.roundId = 0;

    this.onCoefsHistoryChanged = new Event();
    this.onBetsInfoChanged = new Event();

    GlobalDispatcher.add(eWSEventId.EWEI_AUTH, this._onAuth, this);
    GlobalDispatcher.add(eWSEventId.EWEI_BET, this._onBet, this);
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this._onTake, this);
    GlobalDispatcher.add(eWSEventId.EWEI_CANCEL, this._onCancel, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eWSEventId.EWEI_STATISTIC, this._onStatistic, this);
  }

  _onAuth(data) {
    const info = data.params;
    this._activityStats = info.stat;
    this._coefsHistory = info.history.reverse();
    this._betsInfo = info.currentBets || [];
    this._betsInfo.map(item => {
      item.isCurrentPlayer = item.sessionId == getUrlParam('gid');
    });
    this._betInfoCached = info.nextBets || [];
    this._myBetsInfo = info.betsHistory || [];
    if (info.myBets.length > 0) info.myBets.map((item) => {
      if (!item.isTaken) this._myBetsInfo.unshift(item);
    });
    this._myBetsInfo.map(item => {
      item.time = this._getTime(item);
      item.amount = parseFloat(item.amount);
      item.winAmount = parseFloat(item.winAmount);
      item.operatorBetId = typeof(item.operatorBetId) === 'string' && item.operatorBetId.length > 14
        ? item.operatorBetId.slice(0, 12) + '..'
        : item.operatorBetId;
    });
    this.roundId = info.currentRoundId;
    this.onBetsInfoChanged.call();
  }

  _onBet(data) {
    const params = data.params;
    const info = {
      betId: params.betId,
      clientId: params.clientId,
      amount: parseFloat(params.amount),
      roundId: params.roundId,
      isCurrentPlayer: !!params.requestId,
      decimals: params.decimals,
      currency: params.currency,
      time: this._getTime(params),
      operatorBetId: typeof(params.operatorBetId) === 'string' && params.operatorBetId.length > 14
        ? params.operatorBetId.slice(0, 12) + '..'
        : params.operatorBetId
    };

    if (info.isCurrentPlayer) this.addMyBetsInfo(info);

    if (this.roundId === params.roundId) {
      this.addBetInfo(info);
    } else {
      this._betInfoCached.push(info);
    }
    this.onBetsInfoChanged.call();
  }

  _onTake(data) {
    const params = data.params;
    const item = this._betsInfo.find((item) => item.betId === params.betId);

    if (!item) {
      console.log(this._betsInfo);
      console.log(this._betInfoCached);
      return;
    }

    item.coef = params.coef;
    item.winAmount = parseFloat(params.winAmount);
    const myBet = this._myBetsInfo.find((item) => item.betId === params.betId);
    if (myBet) {
      myBet.coef = params.coef;
      myBet.winAmount = parseFloat(params.winAmount);
    }
    this.onBetsInfoChanged.call();
  }

  _onCancel(data) {
    this._betsInfo = this._betsInfo.filter((item) => item.betId !== data.params.betId);
    this._myBetsInfo = this._myBetsInfo.filter((item) => item.betId !== data.params.betId);
    this._betInfoCached = this._betInfoCached.filter((item) => item.betId !== data.params.betId);
    this.onBetsInfoChanged.call();
  }

  _onFinish(data) {
    const coef = data.params.coef;
    this.addCoefsHistory(coef);
    this.roundId = data.params.roundId;

    this._betsInfo = [];
    this.onBetsInfoChanged.call();

    if (data.params.reason !== 'jackpot' || data.params.awards.length === 0) return;

    const awards = data.params.awards;
    for (let i = 0; i < awards.length; i++) {
      const myBet = this._myBetsInfo.find((item) => item.betId === awards[i].betId);
      if (myBet) {
        myBet.coef = coef;
        myBet.winAmount = coef * awards[i].amount + awards[i].jackpot_total * awards[i].jackpot_percent;
      }
    }
  }

  _onStatistic({ params }) {
    this._activityStats = params;
  }

  loadBetsFromCache() {
    for (let i = 0; i < this._betInfoCached.length; i++) {
      const item = this._betInfoCached[i];
      if (item.roundId === this.roundId) {
        this.addBetInfo(item);
      }
    }

    this._betInfoCached = this._betInfoCached.map((item) => item.roundId !== this.roundId);
  }

  _getTime(item) {
    let date = '';
    if (item.createdAt) {
      const arr = item.createdAt.split(/\D/);
      date = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));
    } else {
      date = new Date();
    }
    let M = date.getMonth() + 1;
    M = M < 10 ? '0' + M : M;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    let h = date.getHours();
    h = h < 10 ? '0' + h : h;
    let m = date.getMinutes();
    m = m < 10 ? '0' + m : m;
    return `${d}.${M} ${h}:${m}`;
  }

  get betsInfo() {
    return this._betsInfo;
  }

  addBetInfo(item) {
    this._betsInfo.push(item);
    this.onBetsInfoChanged.call();
  }

  get myBetsInfo() {
    return this._myBetsInfo;
  }

  addMyBetsInfo(item) {
    this._myBetsInfo.unshift(item);
    while (this._myBetsInfo.length > Constants.MAX_MY_BETS_HISTORY) {
      this._myBetsInfo.pop();
    }
  }

  get coefsHistory() {
    return this._coefsHistory;
  }

  addCoefsHistory(value) {
    this._coefsHistory.unshift(value);
    this._coefsHistory = this._coefsHistory.slice(0, Constants.COEFS_HISTORY_COUNT);
    this.onCoefsHistoryChanged.call();
  }

  arrayIncludesMyBets(array) {
    let result = false;
    for (const bet of this._myBetsInfo) {
      result = array.findIndex((item) => item.betId === bet.betId) !== -1;
      if (result) break;
    }
    return result;
  }

  get activityStats() {
    return this._activityStats;
  }
}
